<template>
  <div class="table-container table-5" ref="table" :class="options.classes" v-if="options.rows.length > 0"
       :style="{'max-height': maxHeight}">

    <div class="table-row table-row-head flex-row">
      <div class="table-cell table-cell-tit" v-for="column in options.columns" :key="column.name"
           :class="[tableCellWidth(column.width), {hideMobile: column.hideMobile},{hideOperador: column.hideOperador}]">{{column.name}}</div>
      <div class="table-cell table-cell-5 table-cell-tit" v-if="options.options"></div>
    </div>

    <div class="table-row flex-row" v-for="(data, index) in options.rows" :key="index"
         :class="data.classes">
      <div v-for="(column, index) in options.columns" class="table-cell hoverable" :key="index"
          :class="[tableCellWidth(column.width), {hideMobile: column.hideMobile},{hideOperador: column.hideOperador}]">
        <span :class="[column.classes, data.columnClasses[column.name]]" @click="$emit('ver', data)"
        :title="nameOfDataColumn(data, column)">{{nameOfDataColumn(data, column)}}</span>
        <span v-if="column.html" v-html="column.html"></span>
      </div>

      <div class="table-cell table-cell-5 table-options-container overflowVisible" v-if="options.options">
        <i class="icon-more-filled hoverable" @click.stop.prevent="data.mostrarMenu = !data.mostrarMenu"/>
        <transition name="fade">
          <ul class="options-container shadow" v-if="data.mostrarMenu">
            <li v-for="(button, indexButton) in buttonsToShow(data)" :class="{hideOperador: button.hideOperador}" :key="indexButton">
              <a href="#" @click="$emit(button.fn, data)">{{button.name}}</a>
            </li>
            <li v-if="isActive(data) && toggleActivate(data)">
              <a href="#" @click="cambiarEstado(data, index, 0)">DESACTIVAR</a>
            </li>
            <li v-if="!isActive(data) && toggleActivate(data)">
              <a href="#" @click="cambiarEstado(data, index, 1)">ACTIVAR</a>
            </li>
          </ul>
        </transition>
      </div>
    </div>
  </div>

  <span v-else class="sin-resultados fullwidth">No hay resultados</span>
</template>

<script>
import { EventBus } from '../event-bus.js'
import DateHelper from '../DateHelper'

export default {
  name: 'Table',
  props: ['options'],
  data () {
    return {
      maxHeight: 'calc(100vh - 0)',
      altoAdescontar: 0.00
    }
  },
  methods: {
    isActive (data) {
      return data.state === 1
    },
    toggleActivate (data) {
      return data.showToggleActivate === undefined || data.showToggleActivate
    },

    tableCellWidth (width) {
      return 'table-cell-' + width
    },
    nameOfDataColumn (data, column) {
      /* Esto es un comentario */
      let sufix = column.sufix || ''
      let prefix = column.prefix || ''
      let finalValue = ''
      if (typeof column.rowProperty === 'string') {
        finalValue = data[column.rowProperty]
      } else if (typeof column.rowProperty === 'object') {
        finalValue = data[column.rowProperty.name][column.rowProperty.subname]
      } else if (Array.isArray(column.rowProperty)) {
        finalValue = data[column.rowProperty[0]]
      }

      if (column.transformer !== undefined) {
        finalValue = column.transformer(finalValue)
      }

      return prefix + finalValue + sufix
    },
    cambiarEstado (data, index, nuevoEstado) {
      this.$emit('cambiarEstado', {
        obj: data,
        index: index,
        action: nuevoEstado
      })
    },

    calcularAltoTabla () {
      this.maxHeight = 'calc(95vh - ' + this.altoAdescontar + 'px)'
    },

    ordenarPorCampo (campo) {
      this.options.rows.sort((p1, p2) => {
        if (DateHelper.fechaMayorQue(p1[campo], p2[campo], false)) {
          return 1
        }
        if (DateHelper.fechaMayorQue(p2[campo], p1[campo], false)) {
          return -1
        }
        return 0
      })
    },

    buttonsToShow (row) {
      let buttons = []
      if (this.options.buttons !== undefined) {
        this.options.buttons.forEach((b, index) => {
          if (row.buttons === undefined || row.buttons[index]) {
            buttons.push(b)
          }
        })
      }
      return buttons
    }
  },
  computed: {

  },
  mounted () {
    EventBus.$on('actualizarAltoTabla', (arrHeights) => {
      this.altoAdescontar = 0
      for (let i = 0; i < arrHeights.length; i++) {
        this.altoAdescontar += arrHeights[i]
      }
      this.calcularAltoTabla()
    })
  },
  watch: {
    'options.rows': {
      handler () {
        this.options.columns.forEach((c) => {
          if (c.classes === undefined) {
            c.classes = []
          }
        })

        if (this.options.rows.length > 0) {
          this.options.rows.forEach((r) => {
            if (r.mostrarMenu === undefined) { r.mostrarMenu = false }
            if (r.classes === undefined) { r.classes = {} }
            r.classes['rowNotActive'] = !this.isActive(r)
            if (r.columnClasses === undefined) { r.columnClasses = {} }
          })
        }
      }
    }
  }
}
</script>

<style scoped>
  .table-container
  {
    display: flex;
    flex-direction: column;
    margin: 2vh 2.5vw 0 2.5vw;
    width: calc(100% - 5vw);
    overflow: auto;
    align-items: flex-start;
    background-color: #FFFFFF;
    border: 1px solid var(--main-border-card);
    min-height: 30vh;
  }
  .table-container-noborder {
    border: none;
  }
  .table-container-noscroll {
    margin-top: 0;
    max-height: none;
    overflow: unset;
  }
  .table-container-autoheight {
    min-height: auto;
    overflow: unset;
  }

  .gp-theme .table-container {
    border: none;
  }

  .table-container .table-row
  {
    padding: 0 1vw;
    width: calc(100% - 2vw);
    border-bottom: 1px solid var(--table-row-lines);
    /*padding: 0 25px;*/
  }
  .table-container .table-row.table-row-semaforo:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
  }
  .table-row-semaforo.semaforo-danger:before {background-color: var(--table-row-semaforo-danger);}
  .table-row-semaforo.semaforo-warning:before {background-color: var(--table-row-semaforo-warning);}
  .table-row-semaforo.semaforo-correct:before {background-color: var(--table-row-semaforo-correct);}
  .table-container .table-row:not(.table-row-head):not(.table-row-not-hoverable):hover
  {
    background-color: var(--table-row-hover);
  }

  .table-container .table-row:not(.table-row-head).rowNotActive .table-cell:not(.table-options-container)
  {
    text-decoration: line-through;
    color: var(--secondary-text);
  }

  .table-container .table-cell
  {
    flex: 100%;
    /*overflow: hidden;*/
    list-style: none;
    padding: 2vh 0;
    text-align: left;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin: 0 2vw 0 0;
  }
  .table-container .table-row:not(.table-row-head):nth-child(odd) {
    background-color: var(--table-row-lines)
  }
  .table-cell.table-cell-tit
  {
    font-weight: 600;
    color: var(--table-tits);
    /*border-bottom: 1px solid var(--header-background);*/
  }
  .table-container .table-cell-tit:last-child
  {
    padding: 3vh 0;
  }
  .table-container .table-cell:not(.overflowVisible) {
    overflow: hidden;
  }

  .table-container .hoverable:hover
  {
    cursor: pointer;
    /*color: var(--main-color);*/
    color: #FFFFFF !important;
  }

  .table-container .table-cell span:not(.tag) {
    color: var(--main-text);
  }
  .table-container .rowNotActive .table-cell span {
    color: var(--secondary-text);
  }

  .table-cell.table-cell-50 {flex: 85% !important; width: calc(85% - 2vw);}
  .table-cell.table-cell-50 {flex: 75% !important; width: calc(75% - 2vw);}
  .table-cell.table-cell-50 {flex: 50% !important; width: calc(50% - 2vw);}
  .table-cell.table-cell-35 {flex: 35% !important; width: calc(35% - 2vw);}
  .table-cell.table-cell-30 {flex: 30% !important; width: calc(30% - 2vw);}
  .table-cell.table-cell-25 {flex: 25% !important; width: calc(25% - 2vw);}
  .table-cell.table-cell-20 {flex: 20% !important; width: calc(20% - 2vw);}
  .table-cell.table-cell-15 {flex: 15% !important; width: calc(15% - 2vw);}
  .table-cell.table-cell-10 {flex: 10% !important; width: calc(10% - 2vw);}
  .table-cell.table-cell-5 {flex: 5% !important; width: calc(5% - 2vw);}

  .table-options-container {
    position: relative;
    text-align: right !important;
  }

  .options-container
  {
    position: absolute;
    right: -10px;
    top: 50px;
    background-color: #FFFFFF;
    z-index: 9;
  }
  /*.table-container .table-row:last-child .options-container*/
  /*{*/
  /*  bottom: 50px;*/
  /*  top: auto;*/
  /*}*/
  .options-container li
  {
    list-style-type: none;
  }
  .options-container li a
  {
    padding: 1vh 1vw;
    color: var(--main-text);
    font-size: 0.9rem;
    display: block;
  }
  .options-container li a:hover
  {
    background-color: var(--header-background);
    color: var(--main-color);
  }

  .add-more {
    width: 100px;
    margin-left: 1vw;
  }

  .sin-resultados {
    margin-top: 2vh;
    display: block;
  }

  @media screen and (max-width: 800px)
  {
    /*.table-container:not(.table-container-noscroll)*/
    /*{*/
    /*  max-height: calc(100vh - 270px);*/
    /*}*/

    .options-container li  a{
      padding: 1.5vh 3vw;
    }

    .table-cell:not(.hideMobile):not(.table-options-container) {
      flex: 90%;
      width: 90%;
    }

    .hideMobile {
      display: none;
    }
  }
  .hideOperador {
    display:none;
  }

</style>
