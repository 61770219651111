<template>
  <div class="chips-container flex-row flex-row-left" v-if="hayChipsParaMostrar">
    <div class="chip flex-row" :class="chipTheme(chip)" v-for="(chip, property, index) in chipsToShow" :key="index">
      <span>{{chip.indicador}}: {{chip.chip}}</span>
      <i class="icon icon-close" :title="chip.title" @click="removeChip(property, chip)"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chips',
  props: ['chips'],
  computed: {
    hayChipsParaMostrar () {
      let hay = false
      for (let chipProp in this.chips) {
        if (this.chips[chipProp].show) {
          hay = true
        }
      }
      return hay
    },
    chipsToShow () {
      let chipsShow = {}
      for (let chipProp in this.chips) {
        if (this.chips[chipProp].show) {
          chipsShow[chipProp] = this.chips[chipProp]
        }
      }
      return chipsShow
    }
  },
  methods: {
    removeChip (property, chip) {
      chip.show = false
      this.$emit('removeChip', property)
    },

    chipTheme (chip) {
      return (chip.theme !== undefined) ? chip.theme : 'chip-theme-default'
    }
  }
}
</script>

<style scoped>
  .chips-container {
    padding: 0 2.5vw;
    margin-top: 1vh;
    flex-wrap: wrap;
    display: inline-flex;
  }
  .chip {
    padding: 1vh 1vw;

    background-color: var(--chip-background);
    margin-right: 1vw;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
  .chip.chip-theme-secondary {
    background-color: var(--chip-secondary-background)
  }

  .chip span,
  .chip i {
    color: #FFFFFF;
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
  .chip span {
    margin-right: 7px;
  }
  .chip i {
    font-size: 0.6rem;
    cursor: pointer;
  }

  @media screen and (max-width: 800px) {
    .chip {
      padding: 1vh 2vw;
    }
  }
</style>
